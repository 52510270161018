/**
 * now fresh logo
 * @returns {JSX.Element} image react component with now fresh logo reference
 */
export default function NowFreshLogo() {
  return (
    <img
      srcSet="/now-fresh/Logo.png, /now-fresh/logo-2x.png 2x"
      src="/now-fresh/Logo.png"
      alt="Now Fresh"
    />
  );
}
